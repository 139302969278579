import { DefaultTFuncReturn } from "i18next";
import styles from "./Button.module.css";

export interface IButtonProps {
  type?: "button" | "submit" | "reset" | undefined;
  children: string | DefaultTFuncReturn | JSX.Element;
  disabled?: boolean;
  showAsDisabled?: boolean;
  secondary?: boolean;
  onClick? : () => void;
}

export const Button = (props : IButtonProps) => {

  return <button
    type={props.type ?? "button"}
    className={styles["button"] + " " + (props.secondary ? styles["secondary"] : "") + " " + (props.disabled || props.showAsDisabled ? styles["disabled"] : "")}
    disabled={props.disabled}
    onClick={props.onClick}>
      {props.children}
    </button>
}