import { useEffect, useRef, useState } from "react";
import styles from "./SelectFilterable.module.css";
import { normalizeString } from "../../utils";

interface IOption {
  id: string;
  label: string;
  value: string;
  filterValue?: string;
}

interface ISelectFilterableProps {
  options: IOption[];
  placeholder?: string;
  selectedOption: IOption | null;
  showEmptyOption?: boolean;
  onSelect: (value: IOption | null) => void;
}

export const SelectFilterable = (props: ISelectFilterableProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState("");
  const [selectedOption, setSelectedOption] = useState<IOption | null>(props.selectedOption);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (inputRef.current && !inputRef.current.contains(e.target as Node)) {
        setIsOpen(false);
        setQuery("");
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  const filteredOptions = props.options.filter((option) =>
    normalizeString(option.filterValue ?? option.label).includes(normalizeString(query))
  );

  const handleSelect = (option: IOption | null) => {
    setSelectedOption(option);
    setIsOpen(false);
    setQuery("");
    props.onSelect(option);
  };

  const getDisplayValue = () => (isOpen ? query : selectedOption?.label || "");

  return (
    <div className={styles["select-filterable"]}>
      <div className={styles["query-box-input"]}>
        <input
          ref={inputRef}
          type="text"
          value={getDisplayValue()}
          placeholder={props.placeholder}
          name="searchTerm"
          autoComplete="off"
          onChange={(e) => {
            setQuery(e.target.value);
          }}
          onClick={() => setIsOpen(!isOpen)}
        />
      </div>
      {/* <div className={`${styles["query-box-arrow"]} ${isOpen ? styles["open"] : ""}`}></div> */}
      {isOpen && (
        <div className={`${styles["options"]} ${isOpen ? styles["open"] : ""}`}>
          {props.showEmptyOption &&
            <div
              key="0-empty-option"
              className={styles["option"]}
              onClick={() => handleSelect(null)}
            >&nbsp;</div>
          }
          {filteredOptions.map((option, index) => (
            <div
              key={`${index}-${option.id}`}
              className={`${styles["option"]} ${
                option.label === selectedOption?.label ? styles["selected"] : ""
              }`}
              onClick={() => handleSelect(option)}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};