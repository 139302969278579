import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AnimalsService } from "../../services";
import { ButtonSelector, SelectFilterable } from "..";
import styles from "./Diagnosis.module.css";
import { normalizeString } from "../../utils";

interface IAnimalBreedSelectionProps {
  specieGuid: string;
  breed?: IBreed | null;
  mixedBreed?: boolean;
  onBreedSelected: (breed: IBreed | null) => void;
  onMixedBreedSelected: (mixedBreed: boolean) => void;
}

export interface IBreed {
  guid: string;
  name: string;
  otherNames?: string;
}

export const AnimalBreedSelection = (props: IAnimalBreedSelectionProps, {animalsService = new AnimalsService()}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [breeds, setBreeds] = useState<Array<IBreed>>([]);
  const [mixedBreed, setMixedBreed] = useState<boolean>(props.mixedBreed ?? false);

  const [value, setValue] = useState<IBreed | null>(props.breed ?? null);

  const onBreedChangeHandler = (value: IBreed | null) => {
    setValue(value)
    props.onBreedSelected(value);
  }

  const onMixedBreedChangeHandler = () => {
    setMixedBreed(!mixedBreed);
    props.onMixedBreedSelected(!mixedBreed);
  }

  const loadBreeds = async () => {
    setLoading(true);
    const response = await animalsService.getBreedsWithAllNames(props.specieGuid);
    const apiBreeds = response.data.map((x: IBreed) => x).sort((a: IBreed, b: IBreed) => {
      const nameA = normalizeString(a.name);
      const nameB = normalizeString(b.name);
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });
    setBreeds(apiBreeds);
    setLoading(false);
  }

  useEffect(() => {
    loadBreeds();
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  []);

  return (
    <>
      <h2><label htmlFor="breed">{t("breedSelection")}</label></h2>
      <br />
      {loading && t("loading")}
      {!loading &&
        <>
        <SelectFilterable
          placeholder={t("breedSelectPlaceholder").toString()}
          showEmptyOption={true}
          selectedOption={value ? {id: value.guid, label: value.name, value: value.name} : null}
          options={breeds.map((breed) => ({id: breed.guid, label: breed.name, value: breed.name, filterValue: breed.otherNames ?? breed.name}))}
          onSelect={(option) => option ? onBreedChangeHandler({guid: option.id, name: option.value}) : onBreedChangeHandler(null)}
        />
        <span className={styles["animal-breed-selection-spacer"]} />
        <ButtonSelector onClick={onMixedBreedChangeHandler} selected={props.mixedBreed}>{t("mixedBreed")}</ButtonSelector>
        </>
      }
    </>
  );
}