import { APIResponse, BaseAPI } from "./api";

export class AnimalsService {
  private api: BaseAPI;

  private animalsUrl = "api/animals"
  
  constructor() {
    this.api = new BaseAPI();
  }

  async getAnimals(): Promise<APIResponse<any>> {
    const url = this.animalsUrl
    const response = await this.api.get(url);
    return response;
  }

  async getSpecies(): Promise<APIResponse<any>> {
    const url = this.animalsUrl + "/species"
    const response = await this.api.get(url);
    return response;
  }

  async getBreeds(specieGuid: string): Promise<APIResponse<any>> {
    const url = this.animalsUrl + "/species/" + specieGuid + "/breed"
    const response = await this.api.get(url);
    return response;
  }

  async getBreedsWithAllNames(specieGuid: string): Promise<APIResponse<any>> {
    const url = this.animalsUrl + "/species/" + specieGuid + "/breedWithAllNames"
    const response = await this.api.get(url);
    return response;
  }

  async getSymptoms(): Promise<APIResponse<any>> {
    const url = this.animalsUrl + "/symptoms"
    const response = await this.api.get(url);
    return response;
  }

  async getVaccines(specieGuid: string): Promise<APIResponse<any>> {
    const url = this.animalsUrl + "/species/" + specieGuid + "/vaccines"
    const response = await this.api.get(url);
    return response;
  }
}