
export const isEmptyString = (text: string | undefined | null) : boolean => {
  return text === null || text === undefined || (typeof text !== "string") || text.trim().length === 0;
}

export const getTextBetweenTags = (tag: string, text: string | undefined | null) : string[] => {
  if (isEmptyString(text)) return [];
  if (isEmptyString(tag)) return isEmptyString(text) ? [] : [text as string];

  const inputText = text as string;
  const openTag = "<" + tag + ">";
  const closeTag = "</" + tag + ">";

  const textBetweenTags: string[] = [];
  const splitedText = inputText.split(openTag);
  if (splitedText.length === inputText.split(closeTag).length && splitedText.length > 1) {
    for(let i = 1; i < splitedText.length; i++) {
      const aux = splitedText[i].split(closeTag);
      if (aux.length > 1) {
        textBetweenTags.push(aux[0]);
      }
    }
  }
  
  return textBetweenTags;
}

export const normalizeString = (text: string | undefined | null) : string => {
  if (isEmptyString(text)) return "";

  return text!
    .toLowerCase()
    .normalize("NFD")                 // Decompose characters into base + diacritics
    .replace(/[\u0300-\u036f]/g, ""); // Remove diacritic marks
}
