import { useEffect, useState } from "react";
import { ClinicalCase, DiagnosesService, DiagnosisResponse } from "../../services";
import { AnimalBreedSelection, AnimalSpecieSelection, AnimalAgeSelection, AnimalWeightSelection, AnimalSymptomsSelection, AnimalMedicalHistorySelection, DiagnosisResult, AnimalVaccinesSelection, Button, AnimalGenderSelection, AnimalSterilizedSelection, AnimalFilesSelection, IBreed } from "../../components";
import { useTranslation } from "react-i18next";
import arrowRightSmallIcon from "../../assets/ArrowRightSmall.svg";
import arrowLeftSmallIcon from "../../assets/ArrowLeftSmall.svg";
import styles from "./Diagnosis.module.css";
import { EmptyGuid, isEmptyString, isNullOrUndefined, isValidGuid } from "../../utils";
import { AxiosError } from "axios";
import { useSearchParams } from "react-router-dom";

export enum Step {
  SpecieSelection,
  BreedSelection,
  GenderSelection,
  SterilizedSelection,
  AgeSelection,
  WeightSelection,
  VaccinesSelection,
  MedicalHistorySelection,
  SymptomsSelection,
  DiagnosisResult
}

export const Diagnosis = ({diagnosisService = new DiagnosesService()}) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const allowUploadFiles = searchParams.get("allowFiles") === "1" || searchParams.get("allowfiles") === "1" || searchParams.get("AllowFiles") === "1";
  const [step, setStep] = useState<Step>(Step.SpecieSelection);
  const [specieGuid, setSpecieGuid] = useState<string>(EmptyGuid);
  const [specie, setSpecie] = useState<string>("");
  const [breed, setBreed] = useState<IBreed | null>(null);
  const [mixedBreed, setMixedBreed] = useState<boolean>(false);
  const [gender, setGender] = useState<string>("");
  const [sterilized, setSterilized] = useState<boolean | null>(null);
  const [weight, setWeight] = useState<number>(0);
  //const [age, setAge] = useState<number>(0);
  const [ageRange, setAgeRange] = useState<string>("");
  const [symptoms, setSymptoms] = useState<string>("");
  const [vaccines, setVaccines] = useState<string[]>([]);
  const [medicalHistory, setMedicalHistory] = useState<string>("");
  const [diagnosis, setDiagnosis] = useState<string>("");
  const [diagnosisGuid, setDiagnosisGuid] = useState<string>(EmptyGuid);
  const [files, setFiles] = useState<File[]>([]);

  const nextStep = () => {
    if (step < Step.DiagnosisResult) {
      setStep(step + 1);
    }
  };

  const prevStep = () => {
    if (step > Step.SpecieSelection) {
      setStep(step - 1);
    }
  };

  const showNextButton = () => {
    if (step === Step.SpecieSelection && !isValidGuid(specieGuid)) return false;
    if (step === Step.BreedSelection && isNullOrUndefined(breed) && !mixedBreed) return false;
    if (step === Step.GenderSelection && isEmptyString(gender)) return false;
    if (step === Step.SterilizedSelection && sterilized == null) return false;
    if (step === Step.AgeSelection && isEmptyString(ageRange)) return false;
    // Weight is optional
    // Vaccine is optional
    if (step === Step.SymptomsSelection && isEmptyString(symptoms)) return false;
    // Medical history is optional
    return true;
  }

  const onSpecieSelectedHandler = (specieGuidSelected: string, specieSelected: string) => {
    if(specieGuid !== EmptyGuid && specieGuid !== specieGuidSelected) {
      resetDiagnosis();
    }
    setSpecieGuid(specieGuidSelected);
    setSpecie(specieSelected);
    nextStep();
  }

  const onBreedSelectedHandler = (breedSelected: IBreed | null) => {
    setBreed(breedSelected);
  }

  const onMixedBreedSelectedHandler = (isMixedBreed: boolean) => {
    setMixedBreed(isMixedBreed);
  }

  const onGenderSelctedHandler = (genderSelected: string) => {
    setGender(genderSelected);
    if (!isEmptyString(genderSelected)) {
      nextStep();
    }
  }

  const onSterilizedSelectedHandler = (sterilizedSelected: boolean) => {
    setSterilized(sterilizedSelected);
    nextStep();
  }

  const onAgeRangeSelectedHandler = (ageRangeSelected: string) => {
    setAgeRange(ageRangeSelected);
    if (!isEmptyString(ageRangeSelected)) {
      nextStep();
    }
  }

  const onWeightSelectedHandler = (weightSelected: number) => {
    setWeight(weightSelected);
  }

  const onWeightSubmittedHandler = () => {
    if (weight > 0) {
      nextStep();
    }
  }
  
  const onSymptomsHandler = (symptomsInput: string) => {
    setSymptoms(symptomsInput);
  }

  const onVaccineSelectedHandler = (vaccineGuid: string) => {
    let temp = [...vaccines];
    if (!temp.includes(vaccineGuid)) {
      temp.push(vaccineGuid);
    }
    else {
      temp = temp.filter(x => x !== vaccineGuid);
    }
    setVaccines(temp);
  }
  
  const onMedicalHistoryHandler = (medicalHistoryInput: string) => {
    setMedicalHistory(medicalHistoryInput);
  }

  const onFilesSelectedHandler = (files: File[]) => {
    setFiles(files);
  }

  const resetDiagnosis = () => {
    setSpecieGuid(EmptyGuid);
    setSpecie("");
    setBreed(null);
    setGender("");
    setSterilized(null);
    setMixedBreed(false);
    setWeight(0);
    setAgeRange("");
    setVaccines([]);
    setSymptoms("");
    setMedicalHistory("");
    setFiles([]);
    setDiagnosis("");
    setDiagnosisGuid(EmptyGuid);
    setStep(Step.SpecieSelection);
  };

  const makeDiagnosis = async () => {
    const fullBreed = (breed?.name ?? "") + (mixedBreed ? (" " + t("mixedBreed")) : "");
    const ageTranslated = t("ageRange." + ageRange);
    const clinicalCase = new ClinicalCase(specie, fullBreed, gender, sterilized ?? false, ageTranslated, weight, symptoms, medicalHistory, vaccines, files);

    let response;
    if (files.length > 0) {
      response = await diagnosisService.makeDiagnosisWithFiles(clinicalCase);
    } else {
      response = await diagnosisService.makeDiagnosis(clinicalCase);
    }
    
    if (response.success) {
      let diagnosis = (response.data as DiagnosisResponse);
      setDiagnosis(diagnosis.result);
      setDiagnosisGuid(diagnosis.guid);
    } else {
      const errorResponseData = (response.data as AxiosError<string>).response?.data;
      const errorMsg = "<error>" + t("backend.errors."+errorResponseData) + "</error>";
      setDiagnosis(errorMsg);
      setDiagnosisGuid(EmptyGuid);
    }
  }

  const onFeedbackHandler = async (score: boolean, message: string) => {
    await diagnosisService.sendFeedback(diagnosisGuid, score ? 5 : 0, message);
  }

  useEffect(() => {
    if (step === Step.DiagnosisResult) {
      setDiagnosis("");
      setDiagnosisGuid(EmptyGuid);
      makeDiagnosis();
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [step]);

  const breadCrumb = () => {
    let breadCrumb = "";
    if (!isEmptyString(specie)) {
      breadCrumb = specie.toLowerCase().includes("at") ? "😺" : "🐶";
    }
    if (!isNullOrUndefined(breed) || mixedBreed) {
      breadCrumb += (isNullOrUndefined(breed) ? "" : (" " + breed?.name)) + (mixedBreed ? (" " + t("mixedBreed").toLowerCase()) : "");
    }

    if (!isEmptyString(gender)) {
      breadCrumb += gender === "male" ? " ♂" : " ♀";
    }

    let ageAndWeight = "";
    if (!isEmptyString(ageRange)) {
      ageAndWeight += t("ageRange." + ageRange);
    }
    if (weight > 0) {
      ageAndWeight += " - " + weight + "kg";
    }
    if (!isEmptyString(ageAndWeight)) {
      breadCrumb += " (" + ageAndWeight + ")"
    }
    
    return breadCrumb;
  }

  return (
    <div className={styles["diagnosisContent"]}>
      <div className={styles["diagnosisLayout"]}>
        <div className={styles["diagnosisBreadCrumb"]}>
          <h3>{breadCrumb()}</h3>
          <br />
        </div>
        <div className={styles["diagnosisSelection"]}>
          {step === Step.SpecieSelection && <AnimalSpecieSelection onSpecieSelected={onSpecieSelectedHandler} specieGuid={specieGuid} />}

          {step === Step.BreedSelection && <AnimalBreedSelection onBreedSelected={onBreedSelectedHandler} onMixedBreedSelected={onMixedBreedSelectedHandler} specieGuid={specieGuid} breed={breed} mixedBreed={mixedBreed} />}

          {step === Step.GenderSelection && <AnimalGenderSelection onGenderSelected={onGenderSelctedHandler} gender={gender} />}

          {step === Step.SterilizedSelection && <AnimalSterilizedSelection onSterilizedSelected={onSterilizedSelectedHandler} gender={gender} sterilized={sterilized} />}

          {step === Step.AgeSelection && <AnimalAgeSelection onAgeSelected={onAgeRangeSelectedHandler} ageRange={ageRange} />}

          {step === Step.WeightSelection && <AnimalWeightSelection onWeightSelected={onWeightSelectedHandler} onWeightSubmitted={onWeightSubmittedHandler} selectedWeight={weight} />}

          {step === Step.VaccinesSelection && <AnimalVaccinesSelection onVaccineSelected={onVaccineSelectedHandler} specieGuid={specieGuid} selectedVaccines={vaccines} />}
          
          {step === Step.MedicalHistorySelection && <AnimalMedicalHistorySelection onMedicalHistorySelected={onMedicalHistoryHandler} selectedMedicalHistory={medicalHistory} />}
          
          {step === Step.SymptomsSelection && <AnimalSymptomsSelection onSymptomSelected={onSymptomsHandler} selectedSymptoms={symptoms} />}
          {step === Step.SymptomsSelection && allowUploadFiles && <div><AnimalFilesSelection onFilesSelected={onFilesSelectedHandler} selectedFiles={files} /></div>}

          {!diagnosis && step === Step.DiagnosisResult && <p>{t("loading")}</p>}

          {diagnosis && step === Step.DiagnosisResult && <DiagnosisResult diagnosis={diagnosis} onFeedback={onFeedbackHandler}></DiagnosisResult>}
        </div>

        <div className={styles["navigationButtonsContent"]}>
          <div className={styles["navigationButtons"]}>
            {step > Step.SpecieSelection && 
              <Button onClick={prevStep} secondary={true}>
                <>
                  <img alt="" src={arrowLeftSmallIcon} className={styles["navigationButtonsLeftIcon"]} />
                  {t("buttons.back")}
                </>
              </Button>
            }
            {(step < Step.BreedSelection || !showNextButton()) && <div className={styles["navigationButtonsSpace"]} /> }
            {showNextButton() && step !== Step.DiagnosisResult &&
              <Button onClick={nextStep}>
                <>
                  {step < (Step.DiagnosisResult -1) ? t("buttons.next") : t("makeDiagnosis")}
                  {step < (Step.DiagnosisResult -1) && <img alt="" src={arrowRightSmallIcon} className={styles["navigationButtonsRightIcon"]} />}
                </>
              </Button>
            }
            {showNextButton() && step === Step.DiagnosisResult &&
              <Button onClick={resetDiagnosis}>
                {t("startOver")}
              </Button>
            }
          </div>
        </div>
      </div>

    </div>
  );
}